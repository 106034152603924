import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable, InjectionToken } from '@angular/core';
import { AuthService, CurrentUser } from '@interacta-shared/data-access-auth';
import {
    ConfigurationService,
    defaultSettings,
} from '@interacta-shared/data-access-configuration';
import {
    CustomError,
    ErrorService,
    ErrorType,
} from '@interacta-shared/data-access-error';
import { TipService } from '@interacta-shared/feature-tip';
import { Index, uuid } from '@interacta-shared/util';
import {
    ContentMimeType,
    formatBytes,
    getPathColor,
    getPathIcon,
    isMediaAudio,
    isMediaGif,
    isMediaImage,
    isMediaVideo,
} from '@interacta-shared/util-common';
import { isOrLessIE11 } from '@modules/core/helpers/generic.utils';
import { ImageCompressionService } from '@modules/image-compression/image-compression.service';
import {
    ATTACHMENTS_COUNT_OPERATION_LIMIT,
    AttachmentStorageType,
    AttachmentWithSession,
    IAttachment,
    IAttachmentPost,
    IAttachmentTemporary,
} from '@modules/post/models/attachment/attachment.model';
import { AttachmentService } from '@modules/post/services/attachment.service';
import { UploadActions } from '@modules/upload-dialog/store';
import { Store } from '@ngrx/store';
import {
    FileItem,
    FileUploader,
    FileUploaderOptions,
    ParsedResponseHeaders,
} from 'ng2-file-upload';
import {
    BehaviorSubject,
    firstValueFrom,
    forkJoin,
    from,
    Observable,
    ReplaySubject,
    Subject,
    takeUntil,
} from 'rxjs';

export const IMAGE_COMPRESSION_ENABLED = new InjectionToken<boolean>(
    'IMAGE_COMPRESSION_ENABLED',
);

export interface AttachmentInputConfig {
    acceptedType: string[];
    maxSize: number;
    limit: number;
    context: 'inline' | 'popup';
    initialAttachments: IAttachment[];
    mode: 'eager' | 'lazy';
}

export interface BeforeLoadAttachmentsParams {
    filesLength: number;
}

@Injectable()
export class AttachmentInputService {
    attachments: IAttachment[] = [];
    maxSize: number;
    limit: number = ATTACHMENTS_COUNT_OPERATION_LIMIT;
    acceptedType: string[] = []; // i.e: ['image/*']
    showLoader = false;

    uploader: FileUploader;
    loading = false;
    queueUploaded: any[] = [];
    currentUser: CurrentUser | undefined;
    compressedFileNames: string[] = [];

    protected context: 'inline' | 'popup' = 'inline';

    private _counterFiles = 0;
    private _tmpAttachments: IAttachment[] = [];
    private mapFilesToSessionId: Map<File, string>;
    private mapFilesToAttachmentId = new Map<File, Index>();
    private post?: IAttachmentPost;
    private cancel$ = new Subject<void>();

    onLoading$ = new BehaviorSubject<boolean>(false);

    pendingAttachments$ = new ReplaySubject<IAttachment[]>(1);
    loadAttachmentsEvent$ = new Subject<IAttachment[]>();
    progressItemEvent$ = new Subject<IAttachment>();
    cancelItemEvent$ = new Subject<IAttachment>();
    singleAttachmentLoadedEvent$ = new Subject<IAttachment>();
    startLoadingAttachmentEvent$ = new Subject<AttachmentWithSession[]>();
    limitReachedEvent$ = new Subject<void>();
    errorEvent$ = new Subject<CustomError>();

    beforeLoadAttachmentsFn!:
        | ((params: BeforeLoadAttachmentsParams) => Promise<unknown>)
        | null;

    mode: 'eager' | 'lazy' = 'eager';
    forceUpload$ = new Subject<IAttachmentTemporary>();

    private imageCompressionEnabled = inject(IMAGE_COMPRESSION_ENABLED, {
        optional: true,
    });

    constructor(
        private store: Store,
        private authService: AuthService,
        private attachmentService: AttachmentService,
        private imageCompressionService: ImageCompressionService,
        protected tipService: TipService,
        protected errorService: ErrorService,
        configurationService: ConfigurationService,
    ) {
        this.maxSize = isOrLessIE11()
            ? defaultSettings.attachmentMaxSize
            : configurationService.getEnvironmentInfo()?.attachmentMaxSize ??
              defaultSettings.attachmentMaxSize;
        this.currentUser = this.authService.getCurrentUserData() || undefined;
        this.uploader = new FileUploader({ url: '' });
        this.mapFilesToSessionId = new Map();
        this.beforeLoadAttachmentsFn = null;
    }

    setInitialAttachments(attachments: IAttachment[]): void {
        this.attachments = attachments;
    }

    init(config?: Partial<AttachmentInputConfig>): void {
        this.uploader.onAfterAddingAll = (files: any[]) =>
            this.startLoadingProcedure(files);
        this.uploader.onProgressItem = (item, progress) =>
            this._onProgressItem(item, progress as number);
        this.uploader.onErrorItem = (item, response, status, headers) =>
            this._onErrorItem(item, response, status, headers);
        this.uploader.onCompleteItem = (item, response, status, headers) =>
            this._onCompleteItem(item, response, status, headers);
        this.uploader.onCompleteAll = () => this._onCompleteAll();

        this.initConfigParams(config);
    }

    private initConfigParams(config?: Partial<AttachmentInputConfig>): void {
        if (config) {
            if (config.maxSize) {
                this.maxSize = isOrLessIE11()
                    ? Math.min(
                          defaultSettings.attachmentMaxSize,
                          config.maxSize,
                      )
                    : config.maxSize;
            }
            this.acceptedType = config.acceptedType ?? this.acceptedType;
            this.limit = config.limit ?? this.limit;
            this.context = config.context ?? this.context;
            this.attachments = config.initialAttachments ?? this.attachments;
            this.mode = config.mode ?? this.mode;
        }
    }

    setPost(post: IAttachmentPost): void {
        this.post = post;
    }

    cancelLazyUpload(): void {
        this.uploader.clearQueue();
        this._counterFiles = 0;
        this.cancel$.next();
    }

    private async compressImage(
        fileItem: FileItem,
    ): Promise<{ fileItem: FileItem; compressed?: File }> {
        const rawFile = fileItem.file.rawFile;

        if (this.imageCompressionEnabled) {
            if (
                !this.compressedFileNames.includes(rawFile.name) &&
                rawFile instanceof File
            ) {
                const compressed =
                    await this.imageCompressionService.compressImageFile(
                        rawFile,
                    );
                if (compressed) {
                    this.compressedFileNames.push(rawFile.name);
                    return { fileItem, compressed };
                }
            } else {
                const idx = this.compressedFileNames.indexOf(rawFile.name);
                if (idx >= 0) {
                    this.compressedFileNames.splice(idx, 1);
                }
            }
        }
        return { fileItem };
    }

    async startLoadingProcedure(files: FileItem[]): Promise<void> {
        this._tmpAttachments =
            this.attachments && Array.isArray(this.attachments)
                ? this.attachments.slice()
                : [];
        const _tmpPartialAttachments: IAttachment[] = [];
        const attachmentsUploadSessionId = uuid();
        if (!files) {
            return;
        }
        let areValid = true;

        if (
            !this.checkQueueLimit(
                Math.max(this._tmpAttachments.length, this._counterFiles) +
                    files.length,
            )
        ) {
            //Remove the unaccepted files from the queue if the limit is reached
            files.forEach((fileItem) => {
                this.uploader.removeFromQueue(fileItem);
            });
            return;
        }

        if (this.beforeLoadAttachmentsFn !== null) {
            const continueLoading = await this.beforeLoadAttachmentsFn({
                filesLength: files.length,
            });
            if (!continueLoading) return;
        }

        // ---- image compression ----

        // try to compress each image file, if compression is enabled
        const compressionResult = await firstValueFrom(
            forkJoin(
                files.map((fileItem) => from(this.compressImage(fileItem))),
            ),
        );

        const compressed = compressionResult.filter((item) => item.compressed);
        const notCompressed = compressionResult
            .filter((item) => !item.compressed)
            .map(({ fileItem }) => fileItem);

        // remove from upload queue all original files now compressed
        compressed.forEach(({ fileItem }) =>
            this.uploader.removeFromQueue(fileItem),
        );

        // go on with only not compressed file
        notCompressed.forEach((fileItem: FileItem) => {
            if (!this.checkValidations(fileItem)) {
                areValid = false;
                return;
            }
            _tmpPartialAttachments.push(this.toPartialAttachment(fileItem));
            this.mapFilesToSessionId.set(
                fileItem._file,
                attachmentsUploadSessionId,
            );
        });
        if (areValid && notCompressed.length) {
            this._counterFiles += notCompressed.length;

            this.startLoadingAttachment(
                _tmpPartialAttachments,
                attachmentsUploadSessionId,
            );

            await Promise.all(
                notCompressed.map((fileItem: FileItem) => {
                    fileItem.withCredentials = false;
                    this.loading = true;
                    this.onLoading$.next(true);
                    return this.loadAttachment(fileItem);
                }),
            );
        }

        // re-enqueue all compressed files
        const compressedFilesToAdd = compressed.map(
            ({ compressed }) => compressed as File,
        );
        this.uploader.addToQueue(compressedFilesToAdd);
    }

    clearPendingAttachments(): void {
        this.attachments = [];
        this.pendingAttachments$.next(this.attachments);
    }

    replacePendingAttachment(attachments: IAttachment[]): void {
        this.attachments = [...attachments];
        this.pendingAttachments$.next(this.attachments);
    }

    restoreAttachment(attachment: IAttachment): void {
        this.attachments.push(attachment as IAttachment);
        this.pendingAttachments$.next(this.attachments);
    }

    removeAttachments(attachments: IAttachment[]): void {
        this.attachments = this.attachments.filter(
            (a) => !attachments.some((aa) => aa.id === a.id),
        );
        this.pendingAttachments$.next(this.attachments);
    }

    removeAttachment(attachment: IAttachment): void {
        this.removeAttachments([attachment]);
    }

    cancelAttachmentsUploading(attachments: IAttachment[]): void {
        attachments.forEach((a) => a.fileItem?.cancel());
    }

    private attachmentsLoaded(attachments: IAttachment[]): void {
        this.loadAttachmentsEvent$.next(attachments);
        this.attachments = [...attachments];
        this.pendingAttachments$.next(this.attachments);
    }

    private singleAttachmentLoaded(attachment: IAttachment): void {
        this.singleAttachmentLoadedEvent$.next(attachment);
        if (
            this.context === 'popup' &&
            attachment.fileItem &&
            !attachment.isCanceled &&
            !attachment.isLoadingError
        ) {
            const sessionId = this.mapFilesToSessionId.get(
                attachment.fileItem._file,
            );
            this.store.dispatch(
                UploadActions.attachmentLoadedSuccess({
                    attachment: {
                        ...attachment,
                        attachmentsUploadSessionId: sessionId,
                    },
                }),
            );
            this.mapFilesToSessionId.delete(attachment.fileItem._file);
            this.mapFilesToAttachmentId.delete(attachment.fileItem._file);
        }
    }

    private startLoadingAttachment(
        attachments: IAttachment[],
        attachmentsUploadSessionId: string,
    ): void {
        const attachmentsWithSession: AttachmentWithSession[] = attachments.map(
            (a) => ({ ...a, attachmentsUploadSessionId }),
        );
        this.startLoadingAttachmentEvent$.next(attachmentsWithSession);
    }

    private async loadAttachment(fileItem: FileItem) {
        const post = this.post;

        let obs: Observable<IAttachmentTemporary> | null;
        if (this.mode === 'lazy') {
            obs = this.forceUpload$.asObservable();
        } else {
            obs = this.attachmentService.uploadNewAttachment();
        }

        try {
            const uploadData = await firstValueFrom(
                obs.pipe(takeUntil(this.cancel$)),
            );
            let options: FileUploaderOptions;
            // il nome isLegacy è fuorviante, sarebbe meglio chiamarlo importCustom, quando in futuro aggiorneremo IAttachmentTemporary verrà cambiato
            if (!uploadData.isLegacy) {
                options = <FileUploaderOptions>{
                    url: uploadData.uploadUrl,
                    method: 'POST',
                    parametersBeforeFiles: true,
                    additionalParameter:
                        uploadData.uploadMultipartRequestBodyParams,
                };
            } else {
                const accessToken = this.authService.getCurrentAccessToken();
                options = <FileUploaderOptions>{
                    url: uploadData.uploadUrl,
                    authTokenHeader: 'Authorization',
                    authToken: `Bearer ${accessToken}`,
                };
            }

            this.uploader.isUploading = false; // otherwise multiple load doesn't work
            this.uploader.setOptions(options);
            this.uploader.uploadItem(fileItem);
            this.queueUploaded.push({
                uploadData: { ...uploadData, post },
                fileItem: fileItem,
            });
        } catch (error) {
            if (error instanceof HttpErrorResponse) {
                this.notifyError(error.status);
                this.manageXHRResponse(
                    fileItem,
                    error?.statusText,
                    error.status,
                );
            }
            this._onCompleteAll();
        }
    }

    /* START CONVERSION METHODS */
    private toErrorItem(fileItem: FileItem): IAttachment {
        return {
            ...this.itemToAttachment(fileItem, false),
            markAsHandled: true,
            isLoadingError: true,
        };
    }

    private toCancelItem(fileItem: FileItem): IAttachment {
        return {
            ...this.itemToAttachment(fileItem, false),
            markAsHandled: false,
            isLoadingError: false,
            isCanceled: true,
        };
    }

    private toSuccessItem(
        fileItem: FileItem,
        uploadData: IAttachmentTemporary,
    ): IAttachment {
        return this.itemToAttachment(fileItem, false, uploadData);
    }

    private toPartialAttachment(fileItem: FileItem): IAttachment {
        return {
            ...this.itemToAttachment(fileItem, true),
            markAsHandled: false,
            isLoadingError: false,
        };
    }

    private itemToAttachment(
        fileItem: FileItem,
        isPartial = false,
        uploadData?: IAttachmentTemporary,
    ): IAttachment {
        const cachedId = this.mapFilesToAttachmentId.get(fileItem._file);
        let id: Index;
        if (cachedId) {
            id = cachedId;
        } else {
            id = uuid();
            this.mapFilesToAttachmentId.set(fileItem._file, id);
        }

        return {
            id,
            type: AttachmentStorageType.STORAGE,
            name: fileItem.file.name ?? '',
            contentRef: uploadData ? uploadData.contentRef : undefined,
            contentMimeType: fileItem.file.type
                ? <ContentMimeType>fileItem.file.type
                : ContentMimeType.TXT,
            size: fileItem.file.size,
            isDraft: true,
            temporaryContentDownloadLink: uploadData?.temporaryDownloadUrl,
            temporaryContentPreviewImageLink: isMediaImage(fileItem.file.type)
                ? uploadData?.temporaryDownloadUrl
                : undefined,
            temporaryContentViewLink: uploadData?.temporaryDownloadUrl,
            creationTimestamp: new Date(),
            creatorUser: this.currentUser,
            isMediaVideo: isMediaVideo(fileItem.file.type),
            isMediaAudio: isMediaAudio(fileItem.file.type),
            isMediaImage: isMediaImage(fileItem.file.type),
            isMediaGif: isMediaGif(fileItem.file.type),
            iconPath: getPathIcon(fileItem.file.type),
            iconColor: getPathColor(fileItem.file.type),
            inPending: true,
            isPartial,
            isCanceled: fileItem.isCancel,
            fileItem,
            canDownload: !!uploadData?.temporaryDownloadUrl,
            uploadProgress: fileItem.progress,
            post: uploadData?.post,
        };
    }

    private manageXHRResponse(
        fileItem: FileItem,
        response: any,
        status: number,
    ) {
        if (response) {
            const attachment =
                status === 204
                    ? this.toSuccessItem(fileItem, response)
                    : fileItem.isCancel
                      ? this.toCancelItem(fileItem)
                      : this.toErrorItem(fileItem);
            this.singleAttachmentLoaded(attachment);
            this._counterFiles--;
            !fileItem.isCancel && this._tmpAttachments.push(attachment);
        }
    }

    /* START UPLOADER CALLBACK METHODS*/

    private _onProgressItem(fileItem: FileItem, _progress: number): void {
        const progressItem = this.toPartialAttachment(fileItem);
        this.progressItemEvent$.next(progressItem);
    }

    private _onCompleteItem(
        fileItem: FileItem,
        response: any,
        status: number,
        _headers: ParsedResponseHeaders,
    ): any {
        if (!response) {
            response = this.queueUploaded.find(
                (queue) => queue.fileItem === fileItem,
            ).uploadData;
        } else {
            // IE11 browser
            try {
                response = JSON.parse(response); // contentRef, temporaryDownloadUrl
            } catch (error) {
                //nothing to do
            }
        }
        this.manageXHRResponse(fileItem, response, status);
    }

    private _onErrorItem(
        _fileItem: FileItem,
        response: string,
        status: number,
        _headers: ParsedResponseHeaders,
    ): void {
        this._counterFiles--;
        this.sendLogToServer(response, status);
        this.notifyError(status);
    }

    private _onCompleteAll() {
        if (this._counterFiles === 0) {
            this.attachmentsLoaded(this._tmpAttachments);

            this.queueUploaded = [];
            this.loading = false;
            this.onLoading$.next(false);
        }
    }

    /* END UPLOADER CALLBACK METHODS*/

    private notifyError(status: number) {
        // notify to user that something went wrong
        const message = status
            ? 'LABEL_FORM_ATTACHMENT_ERROR_STATUS'
            : 'LABEL_FORM_ATTACHMENT_ERROR';
        const error = new CustomError(
            `NOTIFICATION_MESSAGE.${message}`,
            false,
            false,
            {
                status,
            },
        );
        this.errorEvent$.next(error);
        this.errorService.handle(error);
    }

    private sendLogToServer(response: string, status: number): void {
        const errorToServer = {
            message: response,
            type: ErrorType.ATTACHMENT,
            cause: `Error (${status}): attachment has not been loaded in storage`,
            stack: null,
        };
        this.errorService.log(errorToServer).subscribe({
            error: () =>
                console.error(
                    'Cannot log error to server side - connection problem.',
                ),
        });
    }

    private checkQueueLimit(counterAttachments: number): boolean {
        if (this.limit && counterAttachments > this.limit) {
            this.limitReachedEvent$.next();
            this.tipService.warn('DETAIL.ERROR_ATTACHMENT_LIMIT', 'manual', {
                limit: this.limit,
            });
            return false;
        } else {
            return true;
        }
    }

    private checkValidations(fileItem: FileItem): boolean {
        if (!fileItem) {
            return false;
        }
        // see https://html.spec.whatwg.org/multipage/input.html#attr-input-accept
        if (
            this.acceptedType?.length > 0 &&
            // match "audio/*", "video/*", "image/*" and MIME type
            !this.acceptedType
                .filter((t) => !new RegExp('^\\.').test(t))
                .find((t) => !!fileItem.file.type?.match(t)) &&
            // match extension (a string whose first character is '.')
            !this.acceptedType
                .filter((t) => new RegExp('^\\.').test(t))
                .find(
                    (t) =>
                        !!new RegExp(`\\${t}$`).test(fileItem.file.name ?? ''),
                )
        ) {
            this.tipService.info('UI.VALIDATION_MESSAGE.mimeTypeNotAdmitted');
            return false;
        }
        if (this.maxSize && fileItem.file.size >= this.maxSize) {
            this.tipService.warn(
                'VALIDATION_BASE.LABEL_ERROR_ATTACHMENT_MAX_SIZE',
                'manual',
                {
                    fileName: fileItem.file.name,
                    maxSize: formatBytes(this.maxSize),
                },
            );
            return false;
        }
        return true;
    }
}
